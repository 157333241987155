import { SVGProps } from 'react';

export interface IconCameraProps extends SVGProps<SVGSVGElement> {
	className?: string;
	testId?: string;
}

const IconCamera = (props: IconCameraProps) => {
	const { testId = 'IconCamera', className, ...remainingProps } = props;

	return (
		<svg
			viewBox="0 0 32 32"
			aria-label="camera icon"
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			className={className}
			{...remainingProps}
		>
			<path
				className="fill-black"
				d="M14.236 6a2.985 2.985 0 0 0-2.683 1.658l-.395.79a.996.996 0 0 1-.894.552H8.5c-2.205 0-4 1.794-4 4v8.5c0 2.206 1.795 4 4 4h15c2.206 0 4-1.794 4-4V13c0-2.206-1.794-4-4-4h-1.764a.994.994 0 0 1-.894-.553l-.395-.789A2.984 2.984 0 0 0 17.765 6h-3.529Zm0 2h3.528c.38 0 .724.212.894.553l.395.789A2.985 2.985 0 0 0 21.736 11H23.5c1.103 0 2 .897 2 2v8.5c0 1.103-.897 2-2 2h-15c-1.103 0-2-.897-2-2V13c0-1.103.897-2 2-2h1.764a2.984 2.984 0 0 0 2.683-1.658l.395-.79A.994.994 0 0 1 14.235 8ZM16 12c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5Zm0 2c1.654 0 3 1.345 3 3 0 1.654-1.346 3-3 3-1.655 0-3-1.346-3-3 0-1.655 1.345-3 3-3Z"
			/>
		</svg>
	);
};

export default IconCamera;
