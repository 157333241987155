import { SVGProps } from 'react';

export interface IconChatGradientProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconChatGradient = (props: IconChatGradientProps) => {
	const { testId = 'IconChatGradient' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 48 48"
			aria-label="Chat Bubble Icon"
			{...props}
		>
			<path
				fill="url(#icon-chat-gradient)"
				d="M11.51 9.053c-1.697 0-3.122.3-4.274.9A5.929 5.929 0 0 0 4.63 12.58c-.586 1.152-.879 2.563-.879 4.232v5.293c0 1.668.294 3.078.88 4.23a5.926 5.926 0 0 0 2.606 2.628c1.152.6 2.427.9 4.124.9v4.118c0 .717.176 1.286.527 1.707.352.42.852.631 1.5.631.455 0 .869-.12 1.24-.361.374-.242.843-.618 1.409-1.128l5.544-4.966h4.475c1.697 0 3.121-.301 4.273-.901a5.926 5.926 0 0 0 2.606-2.628c.586-1.152.88-2.562.88-4.23v-5.293c0-1.67-.293-3.08-.88-4.232a5.926 5.926 0 0 0-2.606-2.628c-1.152-.6-2.576-.9-4.273-.9H11.51Zm0 3h14.545c1.67 0 2.884.396 3.643 1.19.758.793 1.137 1.981 1.137 3.568v5.292c0 1.586-.378 2.78-1.137 3.58-.759.8-1.974 1.2-3.643 1.2h-4.639c-.497 0-.9.052-1.211.155-.31.104-.632.334-.963.693l-5.089 5.193V28.27c0-.51-.107-.87-.32-1.077-.214-.207-.57-.31-1.067-.31H11.51c-1.67 0-2.882-.4-3.641-1.2-.759-.8-1.139-1.994-1.139-3.58V16.81c0-1.586.38-2.774 1.139-3.568.759-.793 1.973-1.19 3.641-1.19Zm24.445 3a13.3 13.3 0 0 1 .112 1.762v1.238h.226c1.672 0 2.887.397 3.645 1.192.757.788 1.14 1.98 1.14 3.57v5.423c0 1.59-.383 2.782-1.14 3.577-.758.802-1.973 1.2-3.645 1.2h-1.252c-.495 0-.855.105-1.065.315-.218.202-.324.562-.324 1.072v4.658l-5.092-5.19c-.33-.36-.652-.592-.96-.697-.307-.105-.713-.158-1.208-.158-5.026 0-4.722.004-4.8-.008l-2.887 2.58c.615.191 1.594.413 3.045.413h4.47l5.55 4.964c.562.51 1.035.885 1.403 1.125.375.24.788.36 1.245.36.645 0 1.148-.21 1.5-.63.352-.42.524-.989.524-1.701V36c1.703 0 2.97-.3 4.125-.9a5.949 5.949 0 0 0 2.61-2.633c.586-1.148.878-2.557.878-4.23v-5.422c0-1.673-.292-3.083-.877-4.238a5.967 5.967 0 0 0-2.61-2.625c-1.156-.6-2.572-.9-4.275-.9h-.338Z"
			/>
			<defs>
				<linearGradient
					id="icon-chat-gradient"
					x1="0"
					x2="48"
					y1="0"
					y2="48"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset=".5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconChatGradient;
