import { SVGProps } from 'react';

export interface ShieldGradientIconProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const ShieldGradientIcon = (props: ShieldGradientIconProps) => {
	const { testId = 'ShieldGradientIcon' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 31 39"
			fill="none"
			aria-label="shield with check icon"
			{...props}
		>
			<path
				fill="url(#a)"
				d="M15.333.882c-.322 0-.643.051-.95.154l-12 3.999A2.996 2.996 0 0 0 .334 7.88v16.655a8.982 8.982 0 0 0 4.008 7.488l8.08 5.387a5.243 5.243 0 0 0 5.824 0l8.08-5.387a8.982 8.982 0 0 0 4.008-7.488V7.881a2.997 2.997 0 0 0-2.05-2.846l-12-4a3.007 3.007 0 0 0-.95-.153Zm0 3.003c.008 0 .015 0 .023.003l11.926 3.975c.03.01.051.04.051.072v16.601a5.998 5.998 0 0 1-2.672 4.992l-8.08 5.387a2.244 2.244 0 0 1-2.496 0l-8.08-5.387a5.998 5.998 0 0 1-2.672-4.992v-16.6c0-.033.021-.062.051-.073L15.31 3.888a.08.08 0 0 1 .023-.003Zm6.722 6.964c-.482.009-.95.25-1.232.684l-7.282 11.24-3.832-4.357a1.5 1.5 0 0 0-2.253 1.982l5.142 5.843a1.503 1.503 0 0 0 1.236.506 1.5 1.5 0 0 0 1.149-.68l8.358-12.902a1.5 1.5 0 0 0-1.286-2.316Z"
			/>
			<defs>
				<linearGradient
					id="a"
					x1={9.441}
					x2={57.298}
					y1={-34.973}
					y2={-15.924}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default ShieldGradientIcon;
