import { SVGProps } from 'react';

export interface WarrantyStarGradientIconProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const WarrantyStarGradientIcon = (props: WarrantyStarGradientIconProps) => {
	const { testId = 'WarrantyStarGradientIcon' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 47 36"
			fill="none"
			aria-label="warranty star badge icon"
			{...props}
		>
			<path
				fill="url(#a)"
				d="M27.846 26.05c-.256 0-.51-.067-.728-.21l-4.117-2.558-4.118 2.558a1.386 1.386 0 0 1-1.523-.038 1.375 1.375 0 0 1-.562-1.417l1.013-4.89-3.683-3.203a1.38 1.38 0 0 1-.412-1.462c.165-.525.63-.9 1.177-.96l4.883-.502 1.942-4.493c.218-.51.72-.832 1.267-.832.548 0 1.05.33 1.268.832l1.942 4.492 4.883.503a1.38 1.38 0 0 1 1.177.96c.165.525 0 1.102-.412 1.463l-3.683 3.202 1.013 4.89a1.393 1.393 0 0 1-.563 1.417c-.24.166-.517.248-.794.248h.03Z"
			/>
			<path
				fill="url(#b)"
				d="M10.873 31.09c-1.38-1.305-1.898-3.135-1.53-4.65.352-1.538 1.485-2.693 3-3.383.518 1.126.6 2.4.39 3.84-.24 1.426-.72 2.97-1.86 4.2v-.007Z"
			/>
			<path
				fill="url(#c)"
				d="M6.733 25.21c-.66-1.523-.495-3.165.292-4.297.765-1.148 2.055-1.778 3.488-1.883.09 1.162-.225 2.272-.878 3.397a7.112 7.112 0 0 1-1.2 1.583 5.493 5.493 0 0 1-1.702 1.2Z"
			/>
			<path
				fill="url(#d)"
				d="M5.225 18.183c0-1.418.638-2.67 1.628-3.345.945-.698 2.197-.833 3.383-.488-.166.502-.353.975-.683 1.395a5.098 5.098 0 0 1-1.11 1.17c-.84.698-1.995 1.245-3.218 1.268Z"
			/>
			<path
				fill="url(#e)"
				d="M6.583 11.125C7.048 10.037 7.933 9.28 8.9 9.04a3.125 3.125 0 0 1 1.44-.037c.48.105.938.322 1.358.63-.293.367-.585.697-.982.937-.36.262-.78.465-1.246.6-.877.293-1.927.345-2.887-.045Z"
			/>
			<path
				fill="url(#f)"
				d="M10.018 5.778c.645-.69 1.515-1.028 2.317-.975.788.022 1.523.465 2.01 1.125-.322.217-.66.397-1.02.51-.367.097-.75.157-1.124.157-.766-.015-1.575-.262-2.176-.817h-.007Z"
			/>
			<path
				fill="url(#g)"
				d="M10.873 31.09a13.275 13.275 0 0 0-4.53-2.82c-1.62-.585-3.383-.825-4.875-.142 1.057 2.302 2.992 3.817 4.8 4.26.907.232 1.777.217 2.572-.023a4.51 4.51 0 0 0 2.033-1.275Z"
			/>
			<path
				fill="url(#h)"
				d="M6.733 25.21a10.592 10.592 0 0 0-2.738-3.758c-1.132-.975-2.497-1.695-3.952-1.582.165 2.137 1.245 3.93 2.527 4.867.675.466 1.373.75 2.078.826a4.08 4.08 0 0 0 2.085-.345v-.008Z"
			/>
			<path
				fill="url(#i)"
				d="M5.225 18.183c.008-1.403-.375-2.716-.96-3.848-.63-1.125-1.485-2.078-2.692-2.43-.263.848-.278 1.71-.203 2.49.113.78.368 1.485.728 2.055.765 1.155 1.875 1.748 3.127 1.725v.008Z"
			/>
			<path
				fill="url(#j)"
				d="M6.583 11.125A6.287 6.287 0 0 0 7.04 7.87a4.697 4.697 0 0 0-.45-1.5 3.167 3.167 0 0 0-.952-1.162c-.435.562-.69 1.215-.863 1.815a4.787 4.787 0 0 0-.06 1.777c.233 1.11.9 1.927 1.86 2.325h.008Z"
			/>
			<path
				fill="url(#k)"
				d="M10.018 5.778a4.72 4.72 0 0 0 1.2-2.333c.135-.847.068-1.672-.435-2.355-.45.315-.803.72-1.058 1.14-.27.413-.427.863-.48 1.305-.12.87.158 1.673.773 2.235v.008Z"
			/>
			<path
				fill="url(#l)"
				d="M14.195 2.935C8.886 6.258 5.728 12.52 6.2 18.58c.308 6.068 4.178 11.663 9.518 14.115.765.367 1.2 1.222.938 2.025-.255.78-1.253 1.14-2.198.705 0 0-.143-.082-.412-.225a11.04 11.04 0 0 1-1.125-.69c-.953-.607-2.25-1.605-3.57-3.03-1.313-1.418-2.633-3.277-3.556-5.497-.495-1.096-.832-2.288-1.117-3.51-.233-1.23-.413-2.49-.405-3.758-.038-1.267.112-2.527.322-3.742.263-1.208.563-2.393 1.028-3.473A18.145 18.145 0 0 1 8.96 6.085a17.776 17.776 0 0 1 3.353-2.97c.442-.315.803-.54 1.057-.682.248-.15.39-.225.39-.225.533-.308.946.397.443.735l-.008-.008Z"
			/>
			<path
				fill="url(#m)"
				d="M12.56 3.513c.18-.878.803-1.628 1.605-2.07.803-.45 1.786-.54 2.61-.248-.84 1.568-2.452 2.445-4.214 2.318Z"
			/>
			<path
				fill="url(#n)"
				d="M35.255 31.33c1.41-1.282 3-2.197 4.643-2.805 1.642-.577 3.42-.802 4.905-.098-1.117 2.326-3.112 3.84-4.942 4.268a6.11 6.11 0 0 1-1.343.157 4.81 4.81 0 0 1-1.252-.21 4.5 4.5 0 0 1-2.01-1.312Z"
			/>
			<path
				fill="url(#o)"
				d="M39.583 25.427c.683-1.522 1.688-2.79 2.813-3.802 1.162-.983 2.512-1.703 3.974-1.582-.172 2.175-1.297 3.99-2.61 4.934-1.342.96-2.864 1.066-4.177.45Z"
			/>
			<path
				fill="url(#p)"
				d="M41.203 18.288c.015-1.433.39-2.76.99-3.908.63-1.14 1.5-2.107 2.693-2.445.27.862.27 1.747.202 2.535-.135.795-.36 1.507-.742 2.085-.758 1.17-1.89 1.762-3.143 1.733Z"
			/>
			<path
				fill="url(#q)"
				d="M39.86 11.088a6.43 6.43 0 0 1-.487-3.308c.128-1.065.555-2.077 1.38-2.685.435.578.712 1.23.885 1.853.135.637.172 1.252.075 1.807-.225 1.125-.885 1.95-1.845 2.34l-.008-.008Z"
			/>
			<path
				fill="url(#r)"
				d="M36.343 5.65a4.906 4.906 0 0 1-1.245-2.355c-.157-.855-.097-1.687.39-2.37.465.315.818.728 1.095 1.148.278.412.443.87.503 1.312.142.878-.128 1.688-.743 2.258v.007Z"
			/>
			<path
				fill="url(#s)"
				d="M35.255 31.33c1.433-1.297 2.018-3.142 1.68-4.688-.315-1.56-1.417-2.744-2.917-3.457-.547 1.14-.668 2.43-.502 3.885.044.36.112.727.202 1.102.082.36.188.728.322 1.095.278.728.668 1.448 1.223 2.063h-.008Z"
			/>
			<path
				fill="url(#t)"
				d="M39.583 25.427c.69-1.537.57-3.21-.21-4.372-.352-.578-.892-1.05-1.477-1.372a5.01 5.01 0 0 0-1.988-.57c-.09 1.184.188 2.317.825 3.465.615 1.125 1.538 2.242 2.85 2.85Z"
			/>
			<path
				fill="url(#u)"
				d="M41.203 18.288c.015-1.448-.623-2.73-1.605-3.428-.953-.72-2.197-.862-3.39-.525.172.51.352.997.683 1.425.27.443.675.84 1.095 1.208.42.36.922.682 1.47.915.54.232 1.117.39 1.74.405h.007Z"
			/>
			<path
				fill="url(#v)"
				d="M39.86 11.088c-.48-1.103-1.372-1.883-2.347-2.138-.975-.262-1.972-.037-2.805.585.285.375.6.713.998.96.36.27.794.473 1.252.623.878.3 1.943.36 2.903-.03Z"
			/>
			<path
				fill="url(#w)"
				d="M36.343 5.65c-.66-.697-1.545-1.042-2.355-.99-.795.023-1.538.465-2.01 1.133.337.217.675.405 1.042.51.375.097.758.157 1.14.157.78-.015 1.583-.255 2.183-.817v.007Z"
			/>
			<path
				fill="url(#x)"
				d="M32.495 2.05c2.933 1.59 5.423 4.02 7.118 6.945a18.559 18.559 0 0 1 2.535 9.765c-.008 6.892-4.23 13.665-10.575 16.808-.938.48-1.995.15-2.28-.615-.3-.78.128-1.666.9-2.056l.367-.172a8.71 8.71 0 0 0 1.02-.517c.87-.458 2.063-1.23 3.308-2.385 1.245-1.155 2.543-2.7 3.51-4.62.982-1.905 1.672-4.148 1.815-6.465.112-1.155.038-2.318-.075-3.465-.172-1.133-.39-2.25-.765-3.285a16.95 16.95 0 0 0-2.872-5.273 16.679 16.679 0 0 0-3.083-3c-.413-.322-.75-.562-.99-.705-.24-.15-.367-.232-.367-.232-.495-.315-.143-1.058.427-.735l.008.007Z"
			/>
			<path
				fill="url(#y)"
				d="M33.733 3.363a4.548 4.548 0 0 1-4.32-2.31c.413-.143.87-.195 1.328-.165.457.03.9.195 1.32.397.825.443 1.47 1.193 1.672 2.078Z"
			/>
			<defs>
				<linearGradient
					id="a"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="b"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="c"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="d"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="e"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="f"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="g"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="h"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="i"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="j"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="k"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="l"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="m"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="n"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="o"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="p"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="q"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="r"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="s"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="t"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="u"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="v"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="w"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="x"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
				<linearGradient
					id="y"
					x1={14.109}
					x2={73.814}
					y1={-32.578}
					y2={6.75}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default WarrantyStarGradientIcon;
