import { SVGProps } from 'react';

export interface IconCheckBadgeProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconCheckBadge = (props: IconCheckBadgeProps) => {
	const { testId = 'IconCheckBadge' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 24 24"
			fill="none"
			aria-label="check badge icon"
			{...props}
		>
			<path
				fill="#0284C7"
				d="M12 .01c-.58 0-1.162.25-1.565.749L9.56 1.842a1.5 1.5 0 0 1-1.556.505L6.66 1.985a2.012 2.012 0 0 0-2.532 1.84l-.072 1.39a1.499 1.499 0 0 1-.96 1.324l-1.302.498a2.013 2.013 0 0 0-.968 2.978l.76 1.167c.324.497.324 1.139 0 1.636l-.76 1.167a2.013 2.013 0 0 0 .968 2.978l1.301.498c.554.212.93.73.961 1.323l.072 1.39a2.012 2.012 0 0 0 2.532 1.84l1.345-.36a1.5 1.5 0 0 1 1.556.504l.875 1.083a2.013 2.013 0 0 0 3.13 0l.877-1.083a1.5 1.5 0 0 1 1.555-.505l1.345.362a2.012 2.012 0 0 0 2.532-1.84l.072-1.39a1.5 1.5 0 0 1 .961-1.324l1.3-.498a2.013 2.013 0 0 0 .968-2.978l-.76-1.167a1.502 1.502 0 0 1 0-1.636l.76-1.167a2.013 2.013 0 0 0-.968-2.978l-1.3-.498a1.5 1.5 0 0 1-.961-1.323l-.072-1.39a2.012 2.012 0 0 0-2.532-1.84l-1.345.36a1.5 1.5 0 0 1-1.555-.504L13.566.759A2.003 2.003 0 0 0 12 .01Zm0 1.993a.58.58 0 0 1 .453.217l.717.886a3 3 0 0 0 3.112 1.011l1.101-.296a.583.583 0 0 1 .733.533l.06 1.138a3 3 0 0 0 1.922 2.647l1.064.408c.347.132.483.55.28.861l-.62.956a3 3 0 0 0 0 3.272l.62.956a.582.582 0 0 1-.28.861l-1.064.408a2.998 2.998 0 0 0-1.923 2.647l-.059 1.139a.582.582 0 0 1-.733.532l-1.1-.296a3 3 0 0 0-3.113 1.01l-.717.887a.583.583 0 0 1-.906 0l-.717-.886a3 3 0 0 0-3.111-1.011l-1.1.296a.583.583 0 0 1-.734-.532l-.059-1.14a3 3 0 0 0-1.923-2.646l-1.065-.408a.582.582 0 0 1-.28-.861l.622-.956a3 3 0 0 0 0-3.272l-.622-.956a.582.582 0 0 1 .28-.861l1.065-.408a2.999 2.999 0 0 0 1.923-2.647l.06-1.138a.582.582 0 0 1 .732-.533l1.101.296a2.998 2.998 0 0 0 3.111-1.01l.717-.887A.58.58 0 0 1 12 2.003Zm3.686 5.504a.997.997 0 0 0-.82.456l-4.137 6.385-1.683-1.913a1 1 0 0 0-1.5 1.32L10.1 16.66a1.002 1.002 0 0 0 1.59-.116l4.854-7.493a1 1 0 0 0-.858-1.544Z"
			/>
		</svg>
	);
};

export default IconCheckBadge;
