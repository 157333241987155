import classNames from 'classnames';
import { SVGProps } from 'react';

export interface IconArrowRightProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconArrowRight = (props: IconArrowRightProps) => {
	const { testId = 'IconArrowRight', className, ...remainingProps } = props;

	return (
		<svg
			width="18"
			height="14"
			viewBox="0 0 18 14"
			fill="none"
			xmlns="http://www.w3.org/2000/svg"
			className={classNames('inline-block', className)}
			aria-label="Arrow Right"
			data-testid={testId}
			{...remainingProps}
		>
			<path d="M11.0801 0.640235C10.8881 0.637141 10.695 0.707172 10.5461 0.851172C10.2488 1.13917 10.2409 1.61434 10.5293 1.91172L14.7297 6.24985H1.5C1.086 6.24985 0.75 6.58585 0.75 6.99985C0.75 7.41385 1.086 7.74985 1.5 7.74985H14.7297L10.5293 12.088C10.2413 12.3854 10.2488 12.8602 10.5461 13.1485C10.692 13.2895 10.8805 13.3602 11.0684 13.3602C11.2641 13.3602 11.4601 13.2839 11.6067 13.1317L17.0383 7.52134C17.32 7.23071 17.32 6.76899 17.0383 6.47837L11.6067 0.868018C11.4631 0.719143 11.2721 0.643329 11.0801 0.640235Z" />
		</svg>
	);
};

export default IconArrowRight;
