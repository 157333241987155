export interface IconBillsGradientProps {
	testId?: string;
}

const IconBillsGradient = (props: IconBillsGradientProps) => {
	const { testId = 'IconBillsGradient' } = props;

	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			data-testid={testId}
			viewBox="0 0 36 36"
			width={40}
			height={30}
			fill="none"
			aria-label="Two money bills icon"
			{...props}
		>
			<path
				fill="url(#a)"
				d="M3.256 0a2.79 2.79 0 0 0-2.79 2.79v18.14c0 1.03.564 1.92 1.395 2.404v1.323a3.5 3.5 0 0 0 3.436 3.487c10.696.142 23.854 1.335 29.014 1.84a3.5 3.5 0 0 0 2.684-.888 3.493 3.493 0 0 0 1.145-2.584v-3.178a2.779 2.779 0 0 0 1.395-2.404V2.79A2.79 2.79 0 0 0 36.745 0H3.255Zm.698 2.79h32.093c.385 0 .697.313.697.698v16.745a.698.698 0 0 1-.697.697H3.954a.698.698 0 0 1-.698-.697V3.488c0-.385.313-.697.698-.697Zm.697 1.396v15.349H35.35V4.186H4.651Zm.698.698h12.339c-1.926 1.211-3.27 3.88-3.27 6.976 0 3.097 1.344 5.766 3.27 6.977H5.349V4.884Zm14.651 0c2.692 0 4.884 3.13 4.884 6.976 0 3.847-2.192 6.977-4.884 6.977s-4.883-3.13-4.883-6.976c0-3.848 2.19-6.977 4.883-6.977Zm2.313 0H34.65v13.953H22.313c1.925-1.211 3.269-3.88 3.269-6.976 0-3.097-1.344-5.766-3.27-6.977ZM4.65 23.72H35.35v2.79a.694.694 0 0 1-.766.694c-5.196-.51-18.447-1.707-29.249-1.852a.689.689 0 0 1-.683-.696v-.936Z"
			/>
			<defs>
				<linearGradient
					id="a"
					x1={12.327}
					x2={63.246}
					y1={-28.75}
					y2={4.168}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconBillsGradient;
