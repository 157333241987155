import * as React from 'react';
import { SVGProps } from 'react';

export interface IconOperatorGradientProps extends SVGProps<SVGSVGElement> {
	testId?: string;
}

const IconOperatorGradient = (props: IconOperatorGradientProps) => {
	const { testId = 'IconOperatorGradient' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			viewBox="0 0 31 35"
			fill="none"
			aria-label="Operator Icon"
			{...props}
		>
			<path
				fill="url(#a)"
				d="M15 .5C6.21.5.75 5.673.75 14v3.636S0 18.918 0 20.75c0 2.085 1.313 3.584 2.864 4.498C4.33 30.75 11.263 35 15 35c3.702 0 10.7-4.088 12.14-9.75h.047a3.567 3.567 0 0 0 3.563-3.563v-2.625a3.554 3.554 0 0 0-1.5-2.894V14c-.134-8.755-7.084-10.463-7.084-10.463S20.11.5 15 .5Zm0 3c4.545 0 5.257 2.745 5.257 2.745S26.25 6.5 26.25 14v1.5h-.656c-.764-1.945-2.237-4.558-5.28-6.237-.122-.068-.375-.198-.375-.198s-.17.227-.257.337C16.192 13.792 10.596 14 5.822 14l-1.5 3H3.75v-3C3.75 7.325 7.853 3.5 15 3.5Zm5.414 9.48c1.014.912 1.752 2.23 2.262 3.434a17.14 17.14 0 0 1 .949 2.966v3.368c-1.584 1.54-4.025 2.306-6.15 2.572a2.235 2.235 0 0 0-1.725-.82 2.25 2.25 0 1 0 1.58 3.85c1.934-.185 4.092-.708 5.987-1.725C21.278 29.82 16.93 32 15 32c-2.565 0-9.413-4.147-9.413-8.82 0 0-2.587-.742-2.587-2.43 0-.277.033-.522.078-.75h3.1l1.502-3.009c3.973-.074 9.115-.698 12.734-4.01Z"
			/>
			<defs>
				<linearGradient
					id="a"
					x1={9.336}
					x2={56.858}
					y1={-32.563}
					y2={-11.537}
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset={0.5} stopColor="#EC4899" />
					<stop offset={1} stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconOperatorGradient;
