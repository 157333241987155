export interface IconScaleGradientProps {
	testId?: string;
}

const IconScaleGradient = (props: IconScaleGradientProps) => {
	const { testId = 'IconScaleGradient' } = props;

	return (
		<svg
			data-testid={testId}
			xmlns="http://www.w3.org/2000/svg"
			fill="none"
			viewBox="0 0 36 36"
			aria-label="Scale Icon"
		>
			<path
				fill="url(#icon_scale_gradient)"
				d="M18 3.599a4.324 4.324 0 0 0-4.088 2.932c-1.99.147-3.633 1.16-4.997 2.016-2.304 1.445-2.897 1.72-4.143-.024a1.44 1.44 0 0 0-2.346 1.673c.931 1.304 1.886 1.974 2.821 2.249L.111 24.642l.006.003a1.425 1.425 0 0 0-.113.67C.2 27.767 1.957 32.402 7.2 32.402s7-4.636 7.195-7.087a1.416 1.416 0 0 0-.112-.67l.005-.002-5.379-12.774c.552-.28 1.075-.593 1.536-.882 1.171-.734 2.294-1.43 3.507-1.572A4.323 4.323 0 0 0 18 12.24a4.323 4.323 0 0 0 4.049-2.826c1.216.142 2.335.838 3.507 1.572.46.29.984.6 1.536.88l-5.38 12.776.006.003a1.424 1.424 0 0 0-.112.67c.196 2.451 1.953 7.086 7.195 7.086s6.999-4.636 7.195-7.087a1.418 1.418 0 0 0-.113-.67l.006-.002-5.136-12.198c.934-.276 1.89-.946 2.82-2.25a1.44 1.44 0 0 0-2.345-1.673c-1.246 1.746-1.839 1.469-4.143.024-1.365-.856-3.005-1.867-4.997-2.014A4.324 4.324 0 0 0 18 3.6Zm0 2.88c.794 0 1.44.646 1.44 1.44 0 .795-.646 1.44-1.44 1.44-.794 0-1.44-.646-1.44-1.44 0-.794.646-1.44 1.44-1.44ZM7.2 15.231l3.591 8.53H3.607L7.2 15.23Zm21.6 0 3.592 8.53H25.21l3.592-8.53ZM3.243 26.64h7.916c-.474 1.287-1.544 2.88-3.959 2.88-2.414 0-3.483-1.593-3.957-2.88Zm21.601 0h7.917c-.474 1.287-1.545 2.88-3.96 2.88-2.414 0-3.483-1.593-3.957-2.88Z"
			/>
			<defs>
				<linearGradient
					id="icon_scale_gradient"
					x1="10.93"
					x2="58.965"
					y1="-24.003"
					y2="5.801"
					gradientUnits="userSpaceOnUse"
				>
					<stop stopColor="#FB923C" />
					<stop offset=".5" stopColor="#EC4899" />
					<stop offset="1" stopColor="#0EA5E9" />
				</linearGradient>
			</defs>
		</svg>
	);
};

export default IconScaleGradient;
